* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
}

html,
body {
  font-size: 62.5%;
}

::-webkit-scrollbar,
scrollbar {
  width: 0.6rem;
}

::-webkit-scrollbar-track,
scrollbar-track {
  background-color: var(--background-primary);
}

::-webkit-scrollbar-thumb,
scrollbar-thumb {
  border-radius: 0.4rem;
  background-color: var(--primary-purple);
}

:root {
  --primary-clr: #fff;
  --primary-orange: #f9ab00;
  --primary-red: #ef4035;
  --primary-hover-red: #f33501;
  --primary-blue: #264853;
  --primary-green: #417b5a;
  --primary-purple: #986dff;
  --primary-purple-hover: #724ace;
  --background-primary: #111111;
  --background-secondary: #151515;
  --shadow-elem: 3px 3px 3px white;
}

.title {
  text-align: center;
  margin: 50px 0px;
  font-size: 2.5rem;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
}

/* NAVBAR */

.navbar-bg {
  position: relative;
  z-index: 99;
  font-size: 1.6rem;
}

.nav-logo {
  width: 13.3rem;
  height: 6.51rem;
  object-fit: contain;
}

.navbar-color {
  background-image: linear-gradient(
    to right,
    rgb(0, 0, 0, 0.6),
    rgb(0, 0, 0, 0.1)
  );
}

/* Menu Links */

.nav-link {
  font-family: "Montserrat", sans-serif !important;
  letter-spacing: 0.1rem !important;
  cursor: pointer;
  color: var(--primary-clr);
  margin-left: 2rem;
  font-size: 1.4rem !important;
  font-weight: 500 !important;
}

.navbar-toggler {
  background-color: white !important;
}

.nav-link {
  color: white !important;
}

.nav-link:hover {
  color: var(--primary-purple) !important;
}

.active {
  color: var(--primary-purple) !important;
}

#responsive-navbar-nav {
  text-transform: uppercase;
}

@media screen and (max-width: 500px) {
  nav a.nav-link {
    font-size: 1rem;
    padding: 0.325rem;
  }
}

/*  intro */

.intro-wrapper {
  position: relative;
  background: url(./images/background.jpg);
  background-size: cover;
  background-position: center;
  height: 100svh;
}

.intro-bg {
  position: relative;
  background: rgba(0, 0, 0, 0.7);
  background-size: cover;
  background-position: center;
  height: 100svh;
}

.main-info {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.main-info h1 {
  color: var(--primary-purple);
  text-transform: capitalize;
  font-size: 4rem;
  letter-spacing: 1px;
  font-weight: 600;
  animation: entrance 900ms ease-out forwards;
}

@keyframes entrance {
  from {
    transform: translateY(-50vh);
  }
  to {
    transform: translateY(0);
  }
}

.typed-text {
  color: white;
  font-size: 2.4rem;
}

#tsparticles {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
}

/* PROJECTS */
.project-container {
  position: relative;
  z-index: 2;
  min-height: 100svh;
  padding: 7rem 0;
  background-color: var(--background-secondary);
}

.project-container > h2 {
  position: relative;
  z-index: 2;
  text-transform: capitalize;
  text-align: center;
  font-size: 3.75rem;
  font-weight: bold;
  color: white;
  padding: 2.4rem 0;
}

.projects {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  gap: 2.4rem;
  max-width: 1250px;
  padding: 4rem;
  margin: 0 auto;
}

.projects h2 {
  font-size: 2.4rem;
  color: var(--primary-purple);
  font-weight: 500;
  margin: 2.4rem 0;
}

.projects p {
  font-weight: 300;
  color: white;
}

.project-div {
  width: 35rem;
  height: 45rem;
  background-color: var(--background-primary);
  text-align: center;
  font-size: 1.6rem;
  padding: 3.2rem;
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  border: 0.2rem solid #1f1f1f;
}

.project-div a {
  font-size: 1.6rem;
  width: 100%;
  margin-top: auto;
  background-color: #fff;
  color: var(--primary-purple);
  border: 0.1rem solid var(--primary-purple);
  background-color: var(--primary-purple);
  color: white;
  padding: 0.8rem;
}

.project-div a:hover {
  background-color: var(--primary-purple-hover);
  color: white;
}

/* ABOUT */

.about {
  position: relative;
  z-index: 2;
  background-color: var(--background-primary);
  padding: 50px 60px 120px 60px;
}

.about h2 {
  position: relative;
  z-index: 2;
  text-transform: capitalize;
  text-align: center;
  font-size: 3.75rem;
  font-weight: bold;
  color: white;
}

.about-row {
  max-width: 1240px !important;
  margin: 0 auto !important;
  background-color: var(--background-secondary);
  padding: 2.4rem;
  color: white;
  border-radius: 0.4rem;
  border: 0.2rem solid #1f1f1f;
}

.about-row h4 {
  font-size: 3.2rem;
  font-weight: 400;
}

.about-row p {
  font-size: 1.8rem;
  font-weight: 400;
  color: rgb(187, 187, 187);
}

.profile-col {
  display: flex;
  justify-content: center;
}

.profile-info-col h4 {
  font-size: 2.4rem;
}

.about-row img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.socials {
  display: flex;
  gap: 1.6rem;
  margin: 0.8rem 0;
}

.socials a {
  width: 7rem;
  height: 7rem;
  border-radius: 0.4rem;
  border: 0.2rem solid #1f1f1f;
  display: flex;
  justify-content: center;
  align-items: center;
}

.socials a:hover {
  border-color: var(--primary-purple);
  cursor: pointer;
}

.socials svg {
  width: 3.2rem;
  height: 3.2rem;
  object-fit: contain;
  color: var(--primary-purple);
}

@media screen and (max-width: 767px) {
  .profile-info-col {
    padding-top: 2.4rem;
  }
}

/* CONTACT */

.contact {
  background-size: cover;
  background-position: center;
  position: relative;
  min-height: 60svh;
  z-index: 2;
  padding: 60px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--background-primary);
}

.contact h2 {
  position: relative;
  z-index: 2;
  text-transform: capitalize;
  text-align: center;
  font-size: 3.75rem;
  margin-bottom: 50px;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: white;
}

.contact-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.contact-content div {
  margin: 10px 30px;
}

.contact-content a,
.emailp {
  text-decoration: none;
  color: white;
  font-size: 2rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.contact-content a:hover {
  color: var(--primary-purple);
}

.iconlinkedin,
.icongithub,
.iconemail {
  font-size: 3.5rem;
  margin: 7px 0px;
}

.icon {
  color: white;
  font-size: 25px;
  margin-right: 5px;
}

.icon:hover {
  color: var(--primary-purple);
}

.emailp {
  text-decoration: none;
  color: white;
  font-size: 2rem;
}

/* FOOTER */

.footer {
  position: relative;
  z-index: 2;
  padding: 3rem;
  background-color: var(--background-primary);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 0.5rem solid #303030;
}

.footer p {
  margin: 0.4rem;
  font-size: 1.4rem;
  font-weight: 500;
}

/* CONTACT FORM*/

.form-contact {
  display: flex;
  flex-direction: column;
  width: 27rem;
}

.form-contact label {
  color: white;
  margin-top: 1rem;
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 0.2rem;
}

.form-contact textarea,
.form-contact input {
  height: 3.5rem;
  resize: none;
  font-size: 1.6rem;
  padding: 0.4rem 0.8rem;
  font-weight: 500;
  border-radius: 0.4rem;
  border: none;
}

.form-contact textarea {
  height: 10rem;
}

.form-contact button {
  margin-top: 1.6rem;
  font-size: 1.6rem;
  font-weight: 600;
  border: none;
  outline: none;
  padding: 0.4rem 1.6rem;
  border-radius: 0.4rem;
}

.form-contact button:hover {
  background-color: var(--primary-purple);
}

.return-p-msg {
  color: white;
  text-align: center;
  font-size: 1.6rem;
}

@media screen and (max-width: 400px) {
  .profile-info-col h4 {
    font-size: 1.8rem;
  }

  .profile-info-col p {
    font-size: 1.4rem;
  }

  .contact-content a,
  .emailp {
    font-size: 1.6rem;
  }

  .iconlinkedin,
  .icongithub,
  .iconemail {
    font-size: 3rem;
  }
}
